import React from 'react'
import { Link } from 'react-router-dom'
import chart from '../img/Gold_Chart.png'
import ItemList from './ItemList'
import TransactionItems from './TransactionItems'
import { FaCcVisa, FaSquareFull } from "react-icons/fa"

const Home = ({ client, clientId, messageCount, totalAvailableBalance, displayedCreditNumber, mergedSortedTransactions }) => {

  

  return (
    <main className='Home'>
    <article className='home__welcome'>
      <h1>Scrooge McDuck's Money Bin</h1>
      <h2>Welcome Back, {client}!</h2>
    </article>

    <article className='home__messages'>
      <h3>
        New Messages
      </h3>
      <Link to="/messages" aria-label="Messages">
        <button type="button">{messageCount}</button>
      </Link>
    </article>
    <article className='home__balance'>
      <h3>
        available Balance
      </h3>
      <p>Client ID: {clientId}</p>
      <p>${totalAvailableBalance}</p>
      <Link to="/accounts" aria-label="Accounts">
        <button type="button">View Accounts</button>
      </Link>
    </article>

    <article className='home__credit'>
      <div className='home__credit__header'>
        <div>
          <h3>
            Credit Card
          </h3>
          {<p>{`**** **** **** ${displayedCreditNumber}`}</p>}
        </div>
        <div>
          <FaCcVisa />
        </div>
      </div>
      <div id='home__balance-bar'>
        <div id='outstanding-bar'></div>
      </div>
      <div className='home__credit__stats'>
        <p><FaSquareFull style={{color: "#da0303"}}/>outStanding</p>
        <p><FaSquareFull style={{color: "#4CAF50"}}/>available</p>
      </div>
      <Link to="/cards" aria-label="Cards">
        <button type="button">View Now</button>
      </Link>
    </article>
    <figure className='home__figure'>
      <img src={chart} className='sdf' alt="Gold projection chart" />
      <figcaption></figcaption>
    </figure>
    <article className='home__transactions'>
      <h3>Recent Transactions</h3>
      <div className='transactions__div'>
        <h4 className='transactions__title'>Date</h4>
        <h4 className='transactions__title'>Retailer</h4>
        <h4 className='transactions__title'>Amount</h4>
      </div>
      {mergedSortedTransactions.length ? (
        <ItemList
          items={mergedSortedTransactions}
        />
      ) : (
        <p style={{marginTop: "2rem"}}>Your list is empty.</p>
      )}
    </article>
    </main>
  )
}

export default Home