import React from 'react'
import { FaHome, FaMoneyBillWaveAlt, FaCreditCard, FaChartLine, FaMailBulk } from "react-icons/fa"
import { NavLink } from 'react-router-dom'
import logo from '../img/Money_Bin_Logo.png'

const Sidenav = ({ percentAvailableBalance }) => {
  return (
    <>
      
      <figure className="side__figure">
        <NavLink to="/" aria-label="Home" onClick={() => {
        setTimeout(() => {
          document.getElementById("outstanding-bar").style.width = `${percentAvailableBalance}%`;
        }, 500);
        }}>
          <img src={logo} className="side__figure__img" alt="logo" />
          <figcaption className="display__none">Scrooge McDuck's Money Bin Logo</figcaption>
        </NavLink>
      </figure>
      
      <nav className='side__nav'>
        <ul className='side__nav__list'>
          <li className='side__nav__list-item'><NavLink to="/" aria-label="Home" onClick={() => {
          setTimeout(() => {
          document.getElementById("outstanding-bar").style.width = `${percentAvailableBalance}%`;
          }, 500);
          }}> <FaHome /> </NavLink> </li> 
          <li className='side__nav__list-item'><NavLink to="/accounts" aria-label="Accounts"> <FaMoneyBillWaveAlt /> </NavLink> </li> 
          <li className='side__nav__list-item'><NavLink to="/cards" aria-label="Cards"> <FaCreditCard /> </NavLink> </li> 
          <li className='side__nav__list-item'><NavLink to="/gold" aria-label="Gold Investments"> <FaChartLine /> </NavLink> </li> 
          <li className='side__nav__list-item'><NavLink to="/messages" aria-label="Messages"> <FaMailBulk /> </NavLink > </li>
        </ul>
      </nav>
    </>
  )
}

export default Sidenav