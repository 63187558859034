import React from 'react'

const TransactionItems = ({ item }) => {
  return (
    <li className='ter'>
      <p>{item.date}</p>
      <p>{item.retailer}</p>
      <p /* style={(item.amount)[0] === "-" ? {color: "red"} : {color: "green"}} */>{item.amount}</p>
    </li>
  )
}

export default TransactionItems