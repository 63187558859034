import React from 'react'
import TransactionItems from './TransactionItems'

const ItemList = ({ items }) => {
  return (
    <ul>
      {items.map((item) => (
        <TransactionItems
          key={item.id}
          item={item}
        />
      ))}
    </ul>
  )
}

export default ItemList