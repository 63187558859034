import React from 'react'

const Messages = ({ messageCount, messages }) => {
  return (
    <main className="Messages">
      <div className='messages__title'>
        <h2>{`${messageCount} Messages in your inbox`}</h2>
      </div>
      <article className='messages__article'>
      <details>
        <summary>Message 1</summary>
        <p class="about__trivia-answer">
          {messages[0]}
        </p>
      </details>
      </article>
      <article className='messages__article'>
      <details>
        <summary>Message 2</summary>
        <p class="about__trivia-answer">
          {messages[1]}
        </p>
      </details>
      </article>
      <article className='messages__article'>
      <details>
        <summary>Message 3</summary>
        <p class="about__trivia-answer">
          {messages[2]}
        </p>
      </details>
      </article>
      <article className='messages__article'>
      <details>
        <summary>Message 4</summary>
        <p class="about__trivia-answer">
          {messages[3]}
        </p>
      </details>
      </article>
    </main>
  )
}

export default Messages