import React, { useEffect } from 'react'
import { useState } from 'react'
import ItemList from './ItemList'
import { FaCcVisa, FaCreditCard, FaRegPaperPlane, FaMoneyCheckAlt, FaFileInvoiceDollar } from "react-icons/fa"

const Accounts = ({ displayedAccountNumbers, clientId, totalAvailableBalance, accountNames, accountBalances, transactionData }) => {

  const list1 = [...transactionData[0]];
  const list2 = [...transactionData[1]];

  const [showList1, setShowList1] = React.useState(true);

  return (
    <main className='Accounts'>
      <article className='accounts__favorite'>
        <h3 className='favorite__title'>Favorite Accounts</h3>
        <div className='favorite__div'>
          <h4 className='favorite__title'>Client ID:</h4>
          <p>{clientId}</p>
        </div>
        <h4 className='favorite__title'>Linked Accounts:</h4>
        <div className='favorite__div'>
          <h4 className='favorite__title'>Account no.</h4>
          {<p>{`**** **** **** ${displayedAccountNumbers[0]}`}</p>}
          <FaCcVisa />
        </div>
        <div className='favorite__div'>
          <h4 className='favorite__title'>Account no.</h4>
          {<p>{`**** **** **** ${displayedAccountNumbers[1]}`}</p>}
          <FaCcVisa />
        </div>
        <hr />
        <h4 className='favorite__title'>Available Balance</h4>
        <p>${totalAvailableBalance}</p>
      </article>
      <article className='accounts__balances'>
        <h3 className='balances__title'>Accounts</h3>
        <div className='balances__div'>
          <h4 className='balances__title'>Account Name</h4>
          <h4 className='balances__title'>Balance</h4>
        </div>
        <div className='balances__div'>
          <label>{accountNames[0]}</label>
          <p>${accountBalances[0]}</p>
        </div>
        <div className='balances__div'>
          <label>{accountNames[1]}</label>
          <p>${accountBalances[1]}</p>
        </div>
      </article>
      <article className='account__actions'>
        <h3 className='actions__title'>Account Actions</h3>
        <div className='actions__div'>
          <ul className='actions__unordered-list'>
            <li className='actions__list-item'>
              <FaCreditCard className='list__icon'/>
              <label>My Card</label>
            </li>
            <li className='actions__list-item'>
              <FaRegPaperPlane className='list__icon'/>
              <label>Transfer</label>
            </li>
            <li className='actions__list-item'>
              <FaMoneyCheckAlt className='list__icon'/>
              <label>Deposit</label>
            </li>
            <li className='actions__list-item'>
              <FaFileInvoiceDollar className='list__icon'/>
              <label>Pay Bill</label>
            </li>
          </ul>
        </div>
        <p style={{fontSize: "1rem"}}>***non-functional actions***</p>
      </article>
      <article className='account__transactions'>
      <h3 className='transactions__title'>Transactions</h3>
      <button onClick={() => setShowList1(true)}>Account 1</button>
      <button onClick={() => setShowList1(false)}>Account 2</button>
      <div className='transactions__div'>
        <h4 className='transactions__title'>Date</h4>
        <h4 className='transactions__title'>Retailer</h4>
        <h4 className='transactions__title'>Amount</h4>
      </div>
      {showList1 ?
        list1.length ? (
          <div className='type-list1'>
            <ItemList
              items={list1}
            />
          </div>
        ) : (
          <p style={{marginTop: "2rem"}}>Your list is empty.</p>
        )
      :
        list2.length ? (
          <div className='type-list2'>
            <ItemList
              items={list2}
            />
          </div>
        ) : (
          <p style={{marginTop: "2rem"}}>Your list is empty.</p>
        )
      }
    </article>
    </main>
  )
}

export default Accounts
