import React from 'react'
import headShoot from "../img/scrooge_profesional.png"
import { goldPriceData } from './Components/Data';
import {Line} from 'react-chartjs-2'
import { Chart as ChartJS } from "chart.js/auto";

const Gold = () => {

  const labels = goldPriceData.map(data => data.time); 
  const prices = goldPriceData.map(data => data.price);

  const dataset = {
    labels: labels,
    datasets: [
      {
        label: 'Gold Prices',
        data: prices,
        fill: false,
        lineTension: 0.5
      }
    ],
  };

  const options = {
    title: {
      display: true,
      fontColor: 'rgba(245, 245, 220, 0.507)',
      text: 'Gold Prices'
    },
    scales: {
      x: {
        ticks: {
          color: 'rgba(245, 245, 220, 0.507)'
        },
        grid: {
          color: 'rgba(245, 245, 220, 0.507)',
          borderColor: 'rgba(245, 245, 220, 0.507)'
        }
      },
      y: {
        ticks: {
          color: 'rgba(245, 245, 220, 0.507)'
        },
        grid: {
          color: 'rgba(245, 245, 220, 0.507)',
          borderColor: 'rgba(245, 245, 220, 0.507)'
        }
      }
    }
  };

  return (
    <main className="Gold">
      <div className='gold__top'>
        <div className='gold__top-container'>
          <figure className='gold__figure'>
            <img src={headShoot} alt="Scrooge McDuck" title='Scrooge McDuck' width={1024} height={1024}  className='gold__img'/>
            <figcaption className='display__none'>
              Scrooge McDuck
            </figcaption>
          </figure>
          <article className='gold__article'>
            <p>Hello everyone! My name is Scrooge McDuck and I am the founder of Scrooge McDuck's Money Bin. I am here to tell you why you should invest your hard-earned money in gold through our bank.</p>
            <br />
            <p>Gold has been a safe and reliable asset for centuries, and its value has always risen over the years. Investing in gold now is a great way to ensure that your financial stability stays secure and that your money will increase in value. </p>
            <br />
            <p>At Scrooge McDuck's Money Bin, we provide our customers with the best in gold investment options. Our expert staff will work with you to understand your needs and tailor a gold investment plan that suits you. </p>
            <br />
            <p>Our bank also offers unbeatable security for our customers, as all gold investments are securely stored onsite. Rest assured, no one but you will ever have access to your gold. </p>
            <br />
            <p>So if you're looking to invest in gold, look no further than Scrooge McDuck's Money Bin!</p>
          </article>
        </div>
      </div>
      <div className='gold__graph-div' style={{ width: 800 }}>
        <Line data={dataset} options={options} className='gold__graph'/>
      </div>
    </main>
  )
}

export default Gold