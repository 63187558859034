import React from 'react'
import ItemList from './ItemList'
import { FaBitcoin, FaStop, FaFileInvoiceDollar, FaScribd, FaArrowAltCircleDown } from "react-icons/fa"

const Cards = ({ displayedCreditNumber, creditCardAvailableBalance, creditCardAccountBalance, creditCardPaymentAmount, creditDueDate, creditInterestRate, creditCardTransactionData }) => {
  return (
    <main className='Cards'>
      <div className="card__container">
        <div className="card__body">
          <div className="card__bank-name">
            Scrooge McDucks's Money Bin
          </div>
          <div className="card__shadow card__number">
          {`**** **** **** ${displayedCreditNumber}`}
          </div>
          <div className="card__good">
            GOOD THRU<div className="card__shadow card__exp">10/23</div>
          </div>
          <div className="card__holder card__shadow">
            Donald Duck
          </div>
        </div>
      </div>
        <article className='card__summary'>
          <h3>Balance</h3>
          <div>
            <h4>Available</h4>
            <p>{creditCardAvailableBalance}</p>
          </div>
          <div>
            <h4>Outstanding</h4>
            <p>{creditCardAccountBalance.toString().slice(1)}</p>
          </div>
          <div>
            <h4>Min. Payment</h4>
            <p>{creditCardPaymentAmount.toString().slice(1,6)}</p>
          </div>
          <div>
            <h4>Payment Due</h4>
            <p>{creditDueDate}</p>
          </div>
          <div>
            <h4>Interest Rate</h4>
            <p>{creditInterestRate.toString().slice(1)}%</p>
          </div>
        </article>
        <article className='card__actions'>
          <h3 className='actions__title'>Account Actions</h3>
          <div className='actions__div'>
            <ul className='actions__unordered-list'>
              <li className='actions__list-item'>
                <FaBitcoin className='list__icon'/>
                <label>Rewards</label>
              </li>
              <li className='actions__list-item'>
                <FaStop className='list__icon'/>
                <label>Freeze Account</label>
              </li>
              <li className='actions__list-item'>
                <FaFileInvoiceDollar className='list__icon'/>
                <label>Pay Bill</label>
              </li>
              <li className='actions__list-item'>
                <FaScribd className='list__icon'/>
                <label>New Card</label>
              </li>
              <li className='actions__list-item'>
                <FaArrowAltCircleDown className='list__icon'/>
                <label>Request Funds</label>
              </li>
            </ul>
          </div>
          <p style={{fontSize: "1rem"}}>***non-functional actions***</p>
        </article>
        <article className='card__transactions'>
          <h3 className='transactions__title'>Transactions</h3>
          <div className='transactions__div'>
            <h4 className='transactions__title'>Date</h4>
            <h4 className='transactions__title'>Retailer</h4>
            <h4 className='transactions__title'>Amount</h4>
          </div>
            {creditCardTransactionData.length ? (
              <ItemList
                items={creditCardTransactionData}
              />
            ) : (
              <p style={{marginTop: "2rem"}}>Your list is empty.</p>
            )}
        </article>
    </main>
  )
}

export default Cards