export const goldPriceData = [
  {
    id: 1,
    time: "8:00am",
    price: 1250.50
  },
  {
    id: 2,
    time: "9:00am",
    price: 1253.50
  },
  {
    id: 3,
    time: "10:00am",
    price: 1258.80
  },
  {
    id: 4,
    time: "11:00am",
    price: 1255.00
  },
  {
    id: 5,
    time: "12:00pm",
    price: 1259.50
  },
  {
    id: 6,
    time: "1:00pm",
    price: 1260.00
  },
  {
    id: 7,
    time: "2:00pm",
    price: 1258.50
  },
  {
    id: 8,
    time: "3:00pm",
    price: 1262.00
  },
  {
    id: 9,
    time: "4:00pm",
    price: 1260.00
  },
  {
    id: 10,
    time: "5:00pm",
    price: 1272.00
  }
]