import { Route, Routes, Link, NavLink, useLocation } from "react-router-dom";
import Sidenav from "./pages/Sidenav";
import Home from "./pages/Home";
import Accounts from "./pages/Accounts";
import Cards from "./pages/Cards";
import Gold from "./pages/Gold";
import Messages from "./pages/Messages";
import { useEffect, useState } from "react";


function App() {

  const [data, setData] = useState(
    {
      user:{
        id: 1,
        user: "Donald Duck",
        userId: 1257654,
        accounts: [
          {
            id: 1,
            accountNumber: 1248647165,
            accountName: "Primary",
            transactions: [
              {
                id: 1.1,
                date: "2020-05-13",
                retailer: "Walmart",
                amount: "-50.00"
              },
              {
                id: 1.2,
                date: "2020-05-10",
                retailer: "Piggly Wiggly",
                amount: "-20.00"
              },
              {
                id: 1.3,
                date: "2020-05-06",
                retailer: "Coffee",
                amount: "-7.50"
              },
              {
                id: 1.4,
                date: "2020-05-05",
                retailer: "Paycheck",
                amount: "+500.00"
              },
              {
                id: 1.5,
                date: "2020-05-04",
                retailer: "Grocery Store",
                amount: "-100.00"
              },
              {
                id: 1.6,
                date: "2020-05-02",
                retailer: "Check Deposit",
                amount: "+300.00"
              }
            ]
          },
          {
            id: 2,
            accountNumber: 1245657961,
            accountName: "Secondary",
            transactions: [
              {
                id: 2.1,
                date: "2020-05-11",
                retailer: "Gas Station",
                amount: "-25.00"
              },
              {
                id: 2.2,
                date: "2020-05-07",
                retailer: "Restaurant",
                amount: "-50.00"
              },
              {
                id: 2.3,
                date: "2020-05-07",
                retailer: "Department Store",
                amount: "-75.00"
              },
              {
                id: 2.4,
                date: "2020-05-05",
                retailer: "ATM",
                amount: "+200.00"
              },
              {
                id: 2.5,
                date: "2020-05-04",
                retailer: "Online Shopping",
                amount: "-45.00"
              },
              {
                id: 2.6,
                date: "2020-05-02",
                retailer: "ATM",
                amount: "+160.00"
              }
            ]
          }
        ],
        "creditCards": [
          { id: 3,
            accountNumber: 1257483652761239,
            creditLimit: 600, 
            dueDate: "2025-02-31", 
            interestRate: ".15", 
            transactions: [
              {
                id: 3.1,
                date: "2020-05-14",
                retailer: "Gas Station",
                amount: "-25.00"
              },
              {
                id: 3.2,
                date: "2020-05-11",
                retailer: "App Store",
                amount: "-150.00"
              },
              {
                id: 3.3,
                date: "2020-05-10",
                retailer: "Fast Food",
                amount: "-10.00"
              },
              {
                id: 3.4,
                date: "2020-05-08",
                retailer: "Phone Bill",
                amount: "-75.00"
              },
              {
                id: 3.5,
                date: "2020-05-05",
                retailer: "Taxi",
                amount: "-25.00"
              },
              {
                id: 3.6,
                date: "2020-05-01",
                retailer: "Netflix",
                amount: "-19.99"
              }
            ] 
          }
        ],
        messages: [
          "You need to watch your spending.",
          "It's time to balance your account.",
          "You need to save more money.",
          "You're doing better with your finances."
        ]
      }}
  );

  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [accountNames, setAccountNames] = useState([]);
  const [transactionData, setTransactionData] = useState(null);
  const [transactionAmounts, setTransactionAmounts] = useState(null);
  const [accountBalances, setAccountBalances] = useState(null);
  const [totalAvailableBalance, setTotalAvailableBalance] = useState(null);
  const [creditCards, setCreditCards] = useState();
  const [creditCardTransactionData, setCreditCardTransactionData] = useState(null);
  const [creditCardAccountBalance, setCreditCardAccountBalance] = useState(0);
  const [creditCardAccountNumber, setCreditCardAccountNumber] = useState(null)
  const [creditLimit, setCreditLimit] = useState(null)
  const [creditDueDate, setCreditDueDate] = useState(null)
  const [creditInterestRate, setCreditInterestRate] = useState(null)
  const [creditCardAvailableBalance, setCreditCardAvailableBalance] = useState(null)
  const [percentAvailableBalance, setPercentAvailableBalance] = useState(null)
  const [mergedSortedTransactions, setMergedSortedTransactions] = useState(null)
  const [messageCount, setMessageCount] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setUserInfo(data);
  },[data])

  const setUserInfo = async (user) => {
    setClient(user.user.user);
    setClientId(user.user.userId);
    setAccounts(user.user.accounts);
  
    if (user && user.user.messages && user.user.messages.length) {
      setMessageCount(user.user.messages.length);
      setMessages(user.user.messages);
    }
  
    const accountNumbers = user.user.accounts.map(account => account.accountNumber);
    setAccountNumbers(accountNumbers);
  
    const accountNames = user.user.accounts.map(account => account.accountName);
    setAccountNames(accountNames);
  
    const transactions = user.user.accounts.map(account => account.transactions);
    setTransactionData(transactions);
  
    const accountAmounts = transactions.map(account =>
      account.map(transaction => transaction.amount)
    );
    setTransactionAmounts(accountAmounts);
  
    const accountBalances = accountAmounts.map(account =>
      account.reduce((acc, curr) => {
        if (curr.startsWith("+")) {
          return acc + Number(curr.slice(1))
        } else if (curr.startsWith("-")) {
          return acc - Number(curr.slice(1))
        } else {
          return acc
        }
      }, 0)
    );
    setAccountBalances(accountBalances);
  
    const totalAvailableBalance = accountBalances.reduce((acc, curr) => acc + curr, 0);
    setTotalAvailableBalance(totalAvailableBalance);
  
    setCreditCards(user.user.creditCards);
  
    const creditCardTransactions = user.user.creditCards.map(creditCard => creditCard.transactions);
    setCreditCardTransactionData([...creditCardTransactions[0]]);
  
    const mergedList = [...transactions[0], ...transactions[1], ...creditCardTransactions[0]]
    const mergedSortedTransactions = mergedList.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setMergedSortedTransactions(mergedSortedTransactions);
  
    let totalBalance = 0;
    user.user.creditCards.forEach(card => {
      card.transactions.forEach(transaction => {
        if (transaction.amount.includes('+')) {
          totalBalance += parseFloat(transaction.amount.slice(1));
        } else {
          totalBalance -= parseFloat(transaction.amount.slice(1));
        }
      });
    });
    setCreditCardAccountBalance(totalBalance);
  
    const creditCardAccountNumber = user.user.creditCards[0].accountNumber;
    setCreditCardAccountNumber(creditCardAccountNumber)
  
    const creditLimit = user.user.creditCards[0].creditLimit;
    setCreditLimit(creditLimit);
  
    const creditDueDate = user.user.creditCards[0].dueDate;
    setCreditDueDate(creditDueDate);
  
    const creditInterestRate = user.user.creditCards[0].interestRate;
    setCreditInterestRate(creditInterestRate);
  
    setCreditCardAvailableBalance(creditLimit - totalBalance);
  
    setPercentAvailableBalance((totalBalance / creditLimit) * (-100));
  }

  if (document.getElementById("outstanding-bar") !== null) {
    document.getElementById("outstanding-bar").style.width = `${percentAvailableBalance}%`;
  }
  
  const displayedCreditNumber =(creditCardAccountNumber%10000);

  const displayedAccountNumbers = accountNumbers.map((account) => account%10000);

  const creditCardPaymentAmount= (creditCardAccountBalance*`1${creditInterestRate}`*.25) < -35 ? creditCardAccountBalance*.25:
  -35
  
  return (
    <>
      <header>
        <Sidenav 
          percentAvailableBalance = {percentAvailableBalance}
        />
      </header>
      {mergedSortedTransactions && creditCardTransactionData &&
      <Routes>
        <Route path="/" element={<Home 
          client = {client}
          clientId = {clientId}
          totalAvailableBalance ={totalAvailableBalance}
          messageCount = {messageCount}
          displayedCreditNumber= {displayedCreditNumber}
          mergedSortedTransactions ={mergedSortedTransactions}
        />} />
        <Route path="/accounts" element={<Accounts 
          displayedAccountNumbers = {displayedAccountNumbers}
          clientId = {clientId}
          totalAvailableBalance = {totalAvailableBalance}
          accountNames = {accountNames}
          accountBalances = {accountBalances}
          transactionData = {transactionData}
        />} />
        <Route path="/cards" element={<Cards 
          displayedCreditNumber = {displayedCreditNumber}
          creditCardAvailableBalance = {creditCardAvailableBalance}
          creditCardAccountBalance = {creditCardAccountBalance}
          creditCardPaymentAmount = {creditCardPaymentAmount}
          creditDueDate = {creditDueDate}
          creditInterestRate = {creditInterestRate}
          creditCardTransactionData = {creditCardTransactionData}
        />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/messages" element={<Messages 
          messageCount = {messageCount}
          messages = {messages}
        />} />
      </Routes>
      }
    </>
  )}

export default App;